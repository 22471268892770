<template>
  <v-container
    class="text-center align-center px-md-16"
    style="height: 80vh; display: grid"
  >
    <v-card class="bg-card rounded-xl py-5 mt-5">
      <h4 class="text-center text-h4 mb-5">BD Interna</h4>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mx-5 mx-md-8 px-md-8">
          <v-col cols="12" md="">
            <label class="ml-4 black--text font-weight-medium">
              URL de acceso
            </label>
            <v-text-field
              placeholder="URL de acceso"
              color="black"
              v-model="updatedData.URL"
              rounded
              :rules="[rules.required, rules.alfanumeric]"
              required
              solo
              class="mt-2"
              :disabled="disabled"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="">
            <label class="ml-4 black--text font-weight-medium">
              Token de acceso
            </label>
            <v-text-field
              placeholder="Token de acceso"
              color="black"
              v-model="updatedData.token"
              rounded
              :rules="[rules.required, rules.alfanumeric]"
              required
              solo
              class="mt-2"
              :disabled="disabled"
              :append-icon="show ? 'fa-eye' : 'fa-eye-slash'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="">
            <label class="ml-4 black--text font-weight-medium">
              Sincronización
            </label>
            <v-select
              placeholder="Sincronización"
              required
              v-model="updatedData.updateFrequency"
              :items="frequencies"
              solo
              rounded
              class="mt-2"
              :disabled="disabled"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>

      <div
        class="
          d-flex
          flex-column flex-md-row
          align-center
          justify-center
          mx-md-16
        "
        v-if="disabled"
      >
        <v-btn
          color="primary"
          class="rounded-lg mr-md-16 my-2 my-md-0 px-5 elevation-0"
          @click="updated"
        >
          <v-icon>fa-pen</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Actualizar</span>
        </v-btn>

        <v-btn
          color="secondary"
          class="rounded-lg ml-md-16 my-2 my-md-0 elevation-0"
        >
          <!-- @click="submit"
          :loading="loading" -->
          <v-icon>fa-sync</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Sincronizar</span>
        </v-btn>
      </div>

      <div
        class="
          d-flex
          flex-column flex-md-row
          align-center
          justify-center
          mx-md-16
        "
        v-else
      >
        <v-btn
          color="secondary"
          outlined
          class="rounded-lg mr-md-16 my-2 my-md-0"
          @click="updated"
        >
          <v-icon>fa-times</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Cancelar</span>
        </v-btn>

        <v-btn
          color="primary"
          class="rounded-lg ml-md-16 my-2 my-md-0 elevation-0"
          @click="submit"
          :loading="loading"
        >
          <v-icon>fa-check</v-icon>
          <span class="text-capitalize ml-2 font-weight-bold">Aceptar</span>
        </v-btn>
      </div>
    </v-card>

    <alerts-cli />
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import AlertsCli from '@/components/Alerts.vue'
import Repository from '@/repositories/RepositoryFactory'

const InternalConfigRepository = Repository.get('internalConfiguration')

export default {
  name: 'InternalDb',
  components: {
    AlertsCli,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      alfanumeric: (value) => {
        const pattern = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
        return !pattern.test(value) || 'Campo inválido.'
      },
    },
    currentData: {
      token: '',
      updateFrequency: '30 * * * *',
      URL: '',
    },
    updatedData: {
      token: '',
      updateFrequency: '30 * * * *',
      URL: '',
    },
    show: false,
    valid: false,
    disabled: true,
    loading: false,
    frequencies: [
      { text: 'Cada media hora', value: '30 * * * *' },
      { text: 'Cada hora', value: '0 * * * *' },
      { text: '1 vez al día', value: '0 9 * * *' },
      { text: '2 veces al día', value: '0 9/12 * * *' },
    ],
  }),
  computed: {
    roleUser: function () {
      return this.$store.state.auth.user?.roleId || ''
    },
  },
  mounted: async function () {
    await this.loadData()
  },
  methods: {
    loadData: async function () {
      this.$eventBus.emit('loading-page')
      try {
        const res = await InternalConfigRepository.get()
        this.currentData = res.data.data
        this.updatedData = Object.assign({}, this.currentData)
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem('user')
          location.reload()
        }

        const response = error.response ? error.response.data.message : error

        console.log(response)
      }
    },
    updated: function () {
      this.disabled = !this.disabled
      this.updatedData = Object.assign({}, this.currentData)
    },

    submit: async function () {
      this.$refs.form.validate()

      setTimeout(async () => {
        if (this.valid) {
          await this.update()
        }
      }, 500)
    },

    update: async function () {
      this.loading = true
      try {
        const res = await InternalConfigRepository.updateToken(this.updatedData)

        const data = {
          type: 'success',
          title: 'Petición exitosa',
          text: res.data.message,
        }

        this.$store.dispatch('alerts/activeAlerts', data)
        this.disabled = true
        this.show = false
        await this.loadData()
      } catch (error) {
        const response = error.response
          ? error.response.data.message ||
            error.response.data.errors?.reduce((acc, cur) => ({
              msg: acc.msg + ', ' + cur.msg,
            })).msg
          : error

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response,
        })
      }
      this.loading = false
    },
  },
}
</script>
