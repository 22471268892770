<template>
  <div>
    <internal-db />
  </div>
</template>

<script>
import InternalDb from '@/components/InternalRecords/InternalDb.vue'

export default {
  components: { InternalDb },
  name: 'InternalDatabase',
}
</script>
